import { EstateInterface } from "@/types/EstateInterface";
import { changePrimaryColor } from "../customer/changePrimaryColor";

export const changePrimaryColorEoiViews = (
  estate: EstateInterface,
  businessId: string | null
): void => {
  if (!businessId || !estate?.collaboratorBusiness){
    changePrimaryColor(estate.iconColor);
    return;
  }
  if (businessId !== estate?.collaboratorBusiness[0]?._id) {
    changePrimaryColor(estate.iconColor);
    return;
  }

  changePrimaryColor(estate.collaboratorBusiness[0]?.branding.color);
};
